<template>
    <v-app>
        <!-- <keep-alive> -->
        <basic-home />
        <!-- </keep-alive> -->

        <!--        <customizer />-->

        <terms
            v-if="dialogTerms"
            :dialog="dialogTerms"
            :title="$t('common.termsDialogTitle')"
            :html-text="termsConditionText"
            @closeDialog="dialogTerms = false"
        />
    </v-app>
</template>

<script>
import { mapGetters /*, mapActions */ } from 'vuex';
import axios from '@/plugins/axios';

export default {
    components: {
        BasicHome: () => import('@/layouts/basic-home/IndexHome'),
        Terms: () => import('@/components/common/Terms')
    },
    data() {
        return {
            dialogTerms: false,
            termsConditionText: []
        };
    },
    async created() {
        // await this.$store.dispatch('configGeneral/loadConfigGeneral');
        const companyId = this.getConfigKeyValue.companyIdSelected ?? '';

        await this.$store.dispatch('cache/loadPosts', { company: companyId });

        await this.loadDontAceptedTerms();
        // setTimeout(this.loadDontAceptedTerms, 1000); // waited 3 seconds for show the terms
    },
    methods: {
        async loadDontAceptedTerms() {
            /* try {
                const terms = await axios
                    .get('legal/dontAcceptedTerms', {
                        params: {
                            term_category_name: this.getLang
                        }
                    });

                if (terms.data?.dontAcceptedTerms?.length) {
                    this.termsConditionText = terms.data.dontAcceptedTerms;
                    this.dialogTerms = true;
                }
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data.error) // "invalid_credentials"
                    console.log(error.response.status)
                }
            } */
        }
    },
    computed: {
        ...mapGetters('languages', ['getLang']),
        ...mapGetters('configGeneral', ['getConfigKeyValue'])
    }
};
</script>

<style lang="css">
@import '~quill/dist/quill.snow.css';
</style>
